<template>
    <v-dialog v-model="dialog"   width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isNew" @click="fetchdata" v-on="on" v-bind="attrs" color="green" depressed class="white--text">
                {{ $t("Manufacturing") }}
            </v-btn>
            <v-icon v-if="!isNew" @click="fetchdata" v-on="on" v-bind="attrs" color="blue">mdi-pen</v-icon>
        </template>
        <v-card>
            <v-card-title class="justify-center">
                <h1>{{ isNew ? $t("Manufacturing") : $t('Manufacturing') }} {{ item.material_name }}</h1>
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row justify="center">
                        <v-col md="4">
                            <v-text-field dense :dark="$store.state.isDarkMode" :label="$t('balance')" outlined type="number"
                                    v-model="structure.quantity">
                                </v-text-field>
                        </v-col>                      
                    </v-row>
                    <v-row justify="center">
                        <v-col md="5">
                            {{ structure.in_account ? $t('Input Account') : '' }}
                            <AutoCompleteField :label="$t('Input Account')" outlined dense no-filter v-model="structure.in_account"
                                 endPoint="/accounting/account/auto-complete" returnObject />
                        </v-col>
                        <v-col md="5">
                            {{ structure.in_inventory ? $t('Input Inventory') : '' }}
                            <AutoCompleteField :label="$t('Input Inventory')" outlined dense no-filter v-model="structure.in_inventory"
                                 endPoint="/inventories/auto-complete" returnObject />
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col md="5">
                            {{ structure.out_account ? $t('Output Account') : '' }}
                            <AutoCompleteField :label="$t('Output Account')" outlined dense no-filter v-model="structure.out_account"
                                 endPoint="/accounting/account/auto-complete" returnObject />
                        </v-col>
                        <v-col md="5">
                            {{ structure.out_inventory ? $t('Output Inventory') : '' }}
                            <AutoCompleteField :label="$t('Output Inventory')" outlined dense no-filter v-model="structure.out_inventory"
                                 endPoint="/inventories/auto-complete" returnObject />
                        </v-col>                
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="green" depressed class="white--text" @click="submit" :loading="btnLoading">{{ isNew ? $t("Manufacturing") : $t('Manufacturing')
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="clearData">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import axios from "axios";
import AutoCompleteField from "../components/core/AutoCompleteField.vue";
export default {
    components: {
        AutoCompleteField
    },
    data() {
        return {
            btnLoading: false,
            dialog: false,
            structure: {
                quantity: 0,
                material_id: null,
                out_account: null,
                in_account: null,
                in_inventory: null,
                out_inventory: null,
            },
        };
    },
    props: {
        isNew: {
            type: Boolean,
            default: true,
        },
        item: {
            type: Object,
            default: () => {
                return {};
            },
        }
    },
    methods: {
        async fetchdata() {
            try {
                let res = await axios.get(`/inventories/materials/meal/default/${this.item.id}`);
                let dataRes = res.data.data
                if(dataRes.out_account){
                    this.structure.out_account = dataRes.out_account;
                }
                if(dataRes.in_account){
                    this.structure.in_account = dataRes.in_account;
                }
                if(dataRes.out_inventory){
                    this.structure.out_inventory = dataRes.out_inventory;
                }
                if(dataRes.in_inventory){
                    this.structure.in_inventory = dataRes.in_inventory;
                }
            } catch (error) {
                
            }
            this.structure.material_id = this.item.id;
            if(this.item.balance > 0) this.structure.quantity = this.item.balance; else this.structure.quantity = this.item.balance * (-1);
        },
        async submit() {
            try {
                this.btnLoading = true;
                this.structure = {
                    ...this.structure,
                    out_account_id: this.structure.in_account?.id,
                    in_account_id: this.structure.in_account?.id,
                    in_inventory_id: this.structure.in_inventory?.id,
                    out_inventory_id: this.structure.out_inventory?.id
                }
             
                    await axios.post("/inventories/materials/meal", this.structure);
                    this.$Notifications(
                        this.$t('add success'),
                        { rtl: true, timeout: 2000 },
                        "success"
                    );
                
                this.$emit("Submitted");
                this.clearData();
            } catch (err) {
            } finally {
                this.btnLoading = false;

            }
        },
        clearData() {
            this.structure.balance = 0;
            this.dialog = false;
        },
    },
   async created() {

        

    },
};
</script>
  
<style>


.btn {
  text-decoration: none;
  height: 41px;
  color: white;
  min-width: 73px;
  padding: 0 18.2222222222px;
  font-size: 1rem;
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-weight: 400;
  letter-spacing: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0;
  text-transform: uppercase;
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
  -webkit-transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: box-shadow, transform, opacity;
  transition-property: box-shadow, transform, opacity, -webkit-box-shadow,
    -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}

</style>