<template>
    <div>
        <v-container class="py-10">
            <v-row>
                <v-col md="12" class="d-flex  pl-5">
                    <v-row>
                    </v-row>
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('meal name')" 
                        v-model="filters.material_name" endPoint="/meal" />
                </v-col>

                <v-col md="3"></v-col>
                <v-col md="1" class="mb-3 py-5">
                    <v-icon :dark="$store.state.isDarkMode" large color="gray" @click="clearFilter">mdi-close</v-icon>
                </v-col>
                <v-col md="1" class="mb-3 py-5">
                    <v-icon :dark="$store.state.isDarkMode" large color="gray" @click="fetchData(1)" >mdi-magnify</v-icon>
                </v-col>

                <v-col md="12">
                    <v-container id="regular-tables" fluid tag="section">
                        <v-simple-table :dark="$store.state.isDarkMode">
                            <thead class="table-heading" v-if="!tableOptions.loading">
                                <tr>
                                    <th v-for="(name, i) in tableOptions.tableHeaders" :key="i">
                                        {{ $t(name) }}
                                    </th>
                                </tr>
                            </thead>
                            <div class="table__spinnerWrapper" v-if="tableOptions.loading">
                                <app-spinner></app-spinner>
                            </div>
                            <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!tableOptions.loading">
                                    <tr v-for="(item, idx) in tableOptions.tableData" :key="idx">
                                        <td>{{ item.id }}</td>
                                        <td>{{ item.material_name }}</td>
                                        <td>{{ item.balance }}</td>
                                        <td>
                                            <Manufacturing @Submitted="fetchData" :item="item"/>
                                        </td>
                                    </tr>
                            </v-fade-transition>
                        </v-simple-table>
                        <div class="d-flex justify-center mt-5">
                            <v-row>
                                <!-- <v-col md="5 ">
            <v-switch
              @change="goToAll"    
              v-model="is_all"
              color="green"
              :label="$t('Show All')"
            ></v-switch>
          </v-col> -->
                                <app-pagination v-if="(!tableOptions.loading)"
                                    :totalPages="tableOptions.tableMeta.total" :page="tableOptions.tableMeta.page"
                                    @PaginationValue="applyPagination($event)"></app-pagination>
                            </v-row>
                        </div>
                    </v-container>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import axios from "axios";
import AutoCompleteField from "../components/core/AutoCompleteField.vue";
import spinner from "../../../components/base/spinner.vue";
import pagination from "../../../components/base/pagination.vue";
import Manufacturing from "./manufacturing.vue";
export default {
    components: {
        AutoCompleteField,
        appSpinner: spinner,
        appPagination: pagination,
        Manufacturing,

    },
    data() {
        return {
            loading: false,
            filters: {
                material_name: null,
            },
            tableOptions: {
                tableData: [],
                tableHeaders: ["id", "name", "balance","settings"],
                accessTableData: [["id"], ["material_name"],["balance"]],
                loading: false,
                tableMeta: {
                    total: null,
                    page: null,
                },
            },
        };
    },
    methods: {
        applyPagination(paginationValue) {
            this.page = paginationValue;
            this.fetchData();
        },
        async fetchData(page) {
            this.tableOptions.loading = true;
            const filterTemp = {};
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) filterTemp[key] = this.filters[key];
            });
            let params = {
                size: 10,
                page: page,
                ...filterTemp,
            };
            const response = await axios.get("/inventories/materials/meal/index", { params });
            if (response.status == 200) {
                this.tableOptions.tableData = response.data.data,
                this.tableOptions.tableMeta.total = response.data.total;
                this.tableOptions.tableMeta.page = response.data.current_page;
            }
            this.tableOptions.loading = false;
        },
        clearFilter() {
            this.filters.material_name = undefined;
            this.fetchData(1);
        },
    },
    created() {

        this.fetchData(1);
    },
};
</script>

<style></style>
